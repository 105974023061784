import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as AOS from 'aos';

import * as skrollr from 'skrollr';

import * as Lazy from 'jquery-lazy';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		// $map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	if($(document).width() > 1024) {
		$('.trigger-btn').on('mouseenter', toggleOverlay);
	} else {
		$('.trigger-btn').on('click', toggleOverlay);
	}
	$('.overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".gallery").lightGallery({
			selector: '.lightgallery' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	$("img[data-src], div[data-src], a[data-src]").Lazy({
		effect: "fadeIn",
        effectTime: 200,
        enableThrottle: true,
        throttle: 250,
        threshold: 400
	});

	// if($map) {
	// 	googleMap.init();
	// }

	[].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
	  img.setAttribute('src', img.getAttribute('data-src'));
	  img.onload = function() {
	    img.removeAttribute('data-src');
	  };
	});

	if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}

	$("select[name='menu-720'] option:first").attr('disabled', 'disabled');

	$('.btn--reservation').on('click', function(e){
		e.preventDefault();
		e.stopPropagation(); 
		
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld, .popup-hld--startpage').removeClass('js-show');
	});

	$('.menu-category li').first().addClass('active');
	$('.menu-categories-content .menu-category-content').first().addClass('active');

	$('.menu-category').on('click', 'li', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		var $this = $(this),
			index = $this.index();

		$('.menu-category li').removeClass('active');
		$this.addClass('active');

		$('.menu-category-content').removeClass('active');

		$('.menu-category-content').fadeOut().promise().done(function(){
			$('.menu-category-content').eq(index).fadeIn().addClass('active');
		});
	});
});

AOS.init({
	disable: 'mobile',
	easing: 'ease-out',
	duration: 1000
});

$(window).on('load', function() {
	
	// if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
	// 	var mySkrollr = skrollr.init({
	// 		edgeStrategy: 'set',
	// 		smoothScrolling: false,
	// 		forceHeight: false
	// 	});
	// }

	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			smoothScrolling: true,
			smoothScrollingDuration: 1000,
			forceHeight: false
		});
	}

});